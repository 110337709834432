import React, { PureComponent } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";

// routes
import Home from './home';
import Quiz from './quiz';
import Results from './result';
import Ads from './ads';

export default class Pages extends PureComponent{
    render(){
        return(
            <BrowserRouter>
                <Switch>
                    <Route path="/" component={Home} exact />
                    <Route path="/:id" component={Quiz} exact />
                    <Route path="/result/:id" component={Results} exact />
                    <Route path="/ads/request" component={Ads} />
                </Switch>
            </BrowserRouter>
        );
    }
}