import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { setStore, connect } from 'trim-redux';
import { Input, Text, Button } from '../../../components';

const Title = styled.h1`
    font-size: 22px;
    font-weight: 500;
    padding: 0 1.5rem;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
`;
const Form = styled.form`
    text-align: center;
`;

class Start extends Component {
    state = {
        message: '',
        messageType: '',
    }
    inputsChange = (e) => {
        setStore({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        if( this.props.name !== '' ){
            this.setState(({
                message: '',
                messageType: ''
            }))
            this.props.onNext();
        } else {
            this.setState(({
                message: 'لطفا نام رو وارد کن',
                messageType: 'warning'
            }))
        }
    }
    render() {
        return (
            localStorage.getItem('token') ? (
                <Redirect to={`/result/${localStorage.getItem('token')}`} />
            ) : (
                <>
                <Title>میخوای ببینی دوستات چقدر میشناسنت؟</Title>
                <Text align="justify" style={{ marginBottom: '1rem', }}>
                    <b>راهنما: </b>
                    تو در چند مرحله، یه سری سوال انتخاب میکنی و از بین جواب های موجود، گزینه درست رو انتخاب میکنی.<br />
                    بعد از این، دوستات همین مراحل رو طی میکنند با این تفاوت که بر اساس شناختی که از تو دارن، سوال درست رو باید جواب بدن.<br />
                    در نهایت از بین تعداد جواب های درست، یه امتیاز بهشون داده میشه و درصد شناخت شون مشخص میشه و میتونن واست ارسال کنند!<br />
                    <b>خیلی خب، بیا شروع کنیم!</b>
                </Text>
                <Form onSubmit={this.handleSubmit} autoComplete="off">
                    <Input
                        type="text"
                        name="name"
                        placeholder="نام"
                        value={this.props.name}
                        warning={this.state.messageType === 'warning'}
                        message={this.state.message}
                        onChange={this.inputsChange}
                        rounded
                    />
                    <Input
                        type="text"
                        name="mobile"
                        placeholder="شماره موبایل یا ایمیل - اختیاری"
                        value={this.props.mobile}
                        onChange={this.inputsChange}
                        rounded
                    />
                    <Button
                        onClick={this.handleSubmit}
                        rounded
                        danger
                        loading={this.props.fetched}
                    >
                        بزن بریم
                    </Button>
                </Form>
                </>
            )
        );
    }
}

const mstp = state => ({
    name: state.name,
    mobile: state.mobile,
    questions: state.questions
})

export default connect(mstp)(Start)