import React, {Component} from 'react';
import styled, { css } from 'styled-components';

const ItemsHolder = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const ItemInner = styled.div`
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 1rem;
`;
const SingleItem = styled.div`
    width: 70%;
    margin: 0 auto;
`;
const SingleItemImageWrapper = styled.div`
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    ${props =>
        props.status === 'currect' ? css`
            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: #43A047;
                opacity: .5;
            }
        `
        : props.status === 'wrong' ? css`
            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: #EF5350;
                opacity: .5;
            }
        `
        : null
    }
`;
const SingleItemImage = styled.img`
    max-width: 100%;
    display: block;
`;
const SingleItemText = styled.span`
    display: block;
    text-align: center;
    margin-top: .5rem;
    font-weight: 500;
    font-size: 14px;
    ${props => props.active && css`
        color: #43A047;
    `}
`;


export default class Items extends Component{
    state = {
        show: false
    }
    addAnswer = (e) => {
        if (this.props.correctAnswer){
            this.setState(({ show: true }))
        }

        const q_id = this.props.id;
        const a_id = e.target.getAttribute('data-answer-id');
        if( this.props.correctAnswer ){
            const isTrue = this.props.correctAnswer === a_id;
            this.props.onAdd(q_id, a_id, isTrue);
        } else {

            this.props.onAdd(q_id, a_id);
        }
    }
    render(){
        return (
            <ItemsHolder>
                {(this.props.items && this.props.items.length) && this.props.items.map((item) => (
                    <ItemInner key={item.id}>
                        <SingleItem>
                            <SingleItemImageWrapper status={this.state.show ? (this.props.correctAnswer === item.id ? 'currect' : 'wrong') : null}>
                                <SingleItemImage
                                    src={`http://quiz.programmerboy.ir/answers/${item.image}`}
                                    data-answer-id={item.id}
                                    alt={item.answer}
                                    onClick={this.addAnswer}
                                />
                            </SingleItemImageWrapper>
                            <SingleItemText>{item.answer}</SingleItemText>
                        </SingleItem>
                    </ItemInner>
                ))}
            </ItemsHolder>
        )
    }
}