import React, { Component } from 'react';
import axios from 'axios';
import { connect, setStore } from 'trim-redux';
import { DefaultLayout } from '../../layout';
import { Text, LinearLoading } from '../../components'
import { Start, Step } from './components';

class Home extends Component {
    state = {
        section: 1,
        step: 1,
        items: [],
        name: ''
    }
    componentDidMount = () => {
        setStore({ questions: [] });
        this.fetchData();
    }
    fetchData = async () => {
        try {

            const response = await axios({
                method: 'get',
                url: `https://quiz.programmerboy.ir/api/v2/answers/${this.props.match.params.id}`
            });

            if(response.status === 200){
                const items = response.data;

                setTimeout(() => {
                    this.setState(({
                        items,
                        name: items[0].user.fullname
                    }));
                }, 1000)
            }

        } catch(e) {
            // Search for console#log
            (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && console.log(e);
        }
    }
    handleStart = () => {
        this.setState(({ section: 2 }));
    }
    handleNext = () => {
        this.setState(prevState => ({ step: prevState.step + 1 }));
    }
    handleSelect = (questionsID, answerID, currectAnswer) => {
        const questions = this.props.questions.concat([{
            question_id: questionsID,
            answer_id: answerID,
            correct: currectAnswer
        }]);

        setStore({ questions });
        setTimeout(() => {
            this.handleNext();
        }, 500);
    }
    render() {
        return (
            <DefaultLayout title="پاسخ به پرسش ها">
                {
                    this.state.items.length ? (
                        this.state.section === 1 ? (
                            <Start fullname={this.state.name} onSubmit={this.handleStart} />
                        ) : this.state.section > 1 ? (
                            <Step
                                items={this.state.items}
                                fullname={this.state.name}
                                currentStep={this.state.step}
                                allSteps={this.state.items.length}
                                onAdd={this.handleSelect}
                                params={this.props.match.params}
                            />
                        ) : null
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '5rem' }}>
                            <Text align="center">منتظر بمانید...</Text>
                            <LinearLoading bgColor="#f61a53" />
                        </div>
                    )
                }
            </DefaultLayout>
        )
    }
}

const mstp = state => ({
    name: state.name,
    questions: state.questions
})
export default connect(mstp)(Home);