import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'trim-redux';
import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Text } from '../../../components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
`;
const Title = styled.h1`
    font-size: 2.5rem;
    color: #f61a53;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1.5rem;
`;
const LinkedButton = styled(Link)`
    display: inline-block;
    padding: .5rem 2rem;
    text-align: center;
    color: #fff;
    box-shadow: 0px 4px 10px #f61a53;
    border: 1px solid #f61a53;
    border-radius: 100px;
    background-color: #f61a53;
    margin-bottom: 1.5rem;
    outline: none !important;
`;
const Bold = styled.b`
    font-weight: bold;
    color: #000;
`;

class Finish extends Component{
    state = {
        degree: null,
        corrects: null
    }
    componentDidMount = () => {
        const ccrrectAnswers = this.props.questions.filter((item) => item.correct === true);
        const degree = this.calcPercent(ccrrectAnswers.length, this.props.questions.length);

        this.setState(({ degree, corrects: ccrrectAnswers.length  }));
        
        // send data for server
        this.sendData();
    }
    calcPercent = (percentFor, percentOf) => {
        return Math.floor(percentFor/percentOf*100);
    }
    sendData = async () => {
        const data = {
            answers: this.props.questions,
            fullname: this.props.name
        };

        try {

            await axios({
                method: 'post',
                url: `https://quiz.programmerboy.ir/api/v2/answers/${this.props.params.id}`,
                data
            });

        } catch(e) {
            // Search for console#log
            (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && console.log(e);
        }
    }
    setProgreccColor = (range) => {
        return range < 20 ? '#F44336'
        : range < 40 ? '#FF5722'
        : range < 60 ? '#FF9800'
        : range < 70 ? '#FFEB3B'
        : range < 80 ? '#8BC34A'
        : range < 90 ? '#00BCD4'
        : range <= 100 ? '#2196F3' : '#78909C'
    }
    render(){
        return (
            <Wrapper>
                {
                    this.state.degree !== null ? (
                        <>
                            <Title>تموم شد!</Title>
                            <Text align="center">حالا اگر دوست داری واسه دوستات سوال طراحی کن و براشون ارسال کن تا شرکت کنن!</Text>
                            <LinkedButton to="/">واسه منم یکی بساز</LinkedButton>
                            <Text align="center" style={{ marginBottom: '2rem', }}>
                                خب. تونستی به <Bold>{this.state.corrects} سوال</Bold> پاسخ درست بدی.
                            </Text>
                            <CircularProgressbar
                                styles={{
                                    root: { width: '40%', marginBottom: '1rem' },
                                    path: {
                                        stroke: this.setProgreccColor(this.state.degree)
                                    },
                                    text: {
                                        fill: this.setProgreccColor(this.state.degree)
                                    }
                                }}
                                value={this.state.degree}
                                text={`${this.state.degree}%`}
                            />
                        </>
                    ) : null
                }
            </Wrapper>
        )
    }
}

const mstp = state => ({
    name: state.name,
    questions: state.questions
})
export default connect(mstp)(Finish)