import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'trim-redux';
import styled from 'styled-components';
import { Text, LinearLoading } from '../../../components';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const Wrapper = styled.div`
    margin-top: 4rem;
`;

const Title = styled.h1`
    font-size: 3rem;
    color: #f61a53;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1.5rem;
`;
const LinkWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    width: 100%;
    padding: .5rem;
    border: 1px solid #ccc;
    border-radius: 100px;
`;
const Link = styled.span`
    max-width: 55%;
    font-family: sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #333;
    direction: ltr;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
const CopyButton = styled.button`
    background: none;
    border: none;
    outline: none !important;
    color: #333;
    padding: .2rem;
`;

class Finish extends Component {
    state = {
        fetching: true,
        code: null
    }
    componentDidMount = async () => {
        // regex
        const mobileRegex = RegExp(/^(\+98|0)?9\d{9}$/g);
        const emailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        const answers = this.props.questions;
        const fullname = this.props.name;
        let type = '';
        
        if(this.props.mobile !== ''){
            if( mobileRegex.test(this.props.mobile) ){
                type = 'phone';
            } else if ( emailRegex.test(this.props.mobile) ){
                type = 'email';
            }
        } else {
            type = 'email';
        }

        const data = {
            answers,
            fullname,
            [type]: this.props.mobile
        };

        try {

            const response = await axios({
                method: 'post',
                url: 'https://quiz.programmerboy.ir/api/v2/questions',
                data
            });

            if( response.status === 200 ){
                if( response.data.code ){
                    this.setState(({ code: response.data.code, fetching: false }));
                    localStorage.setItem('token', response.data.code);
                }
            }

        } catch(e) {
            // Search for console#log
            (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && console.log(e);
        }
    }
    render() {
        return (
            <Wrapper>
                {
                    this.state.fetching ? (
                        <div style={{ textAlign: 'center' }}>
                            <Text align="center">منتظر بمانید...</Text>
                            <LinearLoading bgColor="#f61a53" />
                        </div>
                    ) : (
                        <>
                            <Title>هورررا!</Title>
                            <Text align="center">
                                لینک زیر رو کپی کن و برای دوست هات ارسال کن تا بتونن به سوال هات پاسخ بدن. از طریق وارد شدن به همین سایت، میتونی ببینی که چه کسایی بیشترین پاسخ صحیح رو دادن.
                            </Text>
                            <LinkWrapper>
                                <CopyToClipboard
                                    text={`https://adelina.ir/${this.state.code}`}
                                    onCopy={() => alert('لینک کپی شد!')}
                                >
                                    <CopyButton>کپی کن</CopyButton>
                                </CopyToClipboard>
                                <Link>https://adelina.ir/{this.state.code}</Link>
                            </LinkWrapper>
                        </>
                    )
                }
            </Wrapper>
        )
    }
}
const mstp = state => ({
    name: state.name,
    mobile: state.mobile,
    questions: state.questions
})

export default connect(mstp)(Finish)