import React from 'react';
import styled, {css} from 'styled-components';
import { Link } from 'react-router-dom';
import LinearLoading from './linearLoading';

const Button = styled.button`
    display: ${props => props.block ? 'block' : 'inline-block'};
    ${props => props.block && css`
        width: 100%;
    `}
    padding: ${
        props => props.small ? '.3rem 1rem'
        : props.large ? '1rem 2.5rem'
        : '.5rem 2rem'
    };
    text-align: center;
    color: ${
        props => props.bordered ? (
            props.success ? '#26AF2B'
            : props.confirm ? '#3897F0'
            : props.warning ? '#FF9800'
            : props.danger ? '#f61a53'
            : props.info ? '#8290D8'
            : '#222222'
        )
        : (
            (props.success || props.confirm || props.warning || props.danger || props.info) ? '#fff'
            : '#222222'
        )
    };
    box-shadow: 0px 4px 10px ${
        props => props.success ? '#26AF2B'
        : props.confirm ? '#3897F0'
        : props.warning ? '#FF9800'
        : props.danger ? '#f61a53'
        : props.info ? '#8290D8'
        : '#CFD8DC'
    };
    border: 1px solid ${
        props => props.success ? '#26AF2B'
        : props.confirm ? '#3897F0'
        : props.warning ? '#FF9800'
        : props.danger ? '#f61a53'
        : props.info ? '#8290D8'
        : '#CFD8DC'
    };
    border-radius: ${props => props.rounded ? '100px' : '4px'};
    background-color: ${props => props.bordered ? '#fff'
        : props.success ? '#26AF2B'
        : props.confirm ? '#3897F0'
        : props.warning ? '#FF9800'
        : props.danger ? '#f61a53'
        : props.info ? '#8290D8'
        : '#CFD8DC'
    }
    margin-bottom: ${props => !props.noMargin && '.5rem' };
    outline: none !important;
    cursor: pointer;
    ${props => props.disabled && css`
        filter: grayscale(100%);
    `}
`;
const Icon = styled.i`
    margin-left: .5rem;
    vertical-align: middle;
`;

export default (props) => {
    return (
        props.to ? (
            <Link to={props.to} style={{ display: 'inline-table', ...props.linkStyle}}>
                <Button {...props}>
                    {
                        props.loading ? (<LinearLoading small={props.small} large={props.large} />) : (
                            <>
                                {props.icon ? <Icon className={props.icon} /> : null}
                                {props.children}
                            </>
                        )
                    }
                </Button>
            </Link>
        ) : (
            <Button {...props}>
                {
                    props.loading ? (<LinearLoading small={props.small} large={props.large} />) : (
                        <>
                            {props.icon ? <Icon className={props.icon} /> : null}
                            {props.children}
                        </>
                    )
                }
            </Button>
        )
    )
}