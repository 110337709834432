import React, { Component } from 'react';
import { connect, setStore } from 'trim-redux';
import axios from 'axios';
import { DefaultLayout } from '../../layout';
import { Start, Step } from './components';

class Home extends Component {
    state = {
        section: 1,
        step: 1,
        fetched: false,
        items: []
    }
    componentDidMount = () => {
        setStore({ questions: [] });
    }
    handleNextSection = () => {
        this.fetchData();
    }
    fetchData = async () => {
        this.setState(({ fetched: true }));
        try {

            const response = await axios({
                method: 'get',
                url: 'https://quiz.programmerboy.ir/api/v2/questions'
            });

            if(response.status === 200){
                const items = response.data;
                this.setState(({ items, section: 2 }));
            }

        } catch(e) {
            // Search for console#log
            (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && console.log(e);
        }
    }
    handleNext = () => {
        this.setState(prevState => ({ step: prevState.step + 1 }));
    }
    handleAdd = (questionsID, answerID) => {
        const questions = this.props.questions.concat([{
            question_id: questionsID,
            answer_id: answerID
        }]);
        setStore({ questions });
        setTimeout(() => {
            this.handleNext();
        }, 300);
    }
    render() {
        return (
            <DefaultLayout title="ساخت پرسش نامه">
                {
                    this.state.section === 1 ? (
                        <Start onNext={this.handleNextSection} fetched={this.state.fetched} />
                    ) : this.state.section >= 1 ? (
                        <Step
                            items={this.state.items}
                            currentStep={this.state.step}
                            allSteps={this.state.items.length}
                            name={this.props.name}
                            onSkip={this.handleNext}
                            onAdd={this.handleAdd}
                        />
                    ) : null
                }
                
            </DefaultLayout>
        )
    }
}

const mstp = state => ({
    name: state.name,
    mobile: state.mobile,
    questions: state.questions
})

export default connect(mstp)(Home)