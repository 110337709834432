import React, { Component } from 'react';
import { StepHolder } from '../../../components';
import Finish from './finish';

class Step extends Component {
    render() {
        return (
            this.props.currentStep > this.props.items.length ? (
                <Finish params={this.props.params} fullDegree={this.props.allSteps} />
            ) : (
                (this.props.items && this.props.items.length) ? this.props.items.map((item, i) => (
                    <StepHolder
                        key={i}
                        active={this.props.currentStep === (i + 1)}
                        allSteps={this.props.allSteps}
                        answers={item.question.answers}
                        currentStep={this.props.currentStep}
                        correctAnswer={item.correct_answer}
                        stepTitle={item.question.question.replace('[name]', this.props.fullname)}
                        onAdd={this.props.onAdd}
                        isQuiz
                        id={item.question.id}
                    />
                )) : null
            )
        )
    }
}

export default Step;