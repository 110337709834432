import React from 'react';
import styled from 'styled-components';
import Text from './text';

const InputMessageWrapper = styled.div`
    display: block;
`;
const InputMessage = styled(Text)`
    position: relative;
    margin: 0;
    padding: .3rem .5rem;
    background-color: ${props =>
        props.success ? '#E8F5F9'
        : props.warning ? '#FFF3E0'
        : props.danger ? '#FFEBEE'
        : '#f5f5f5'
    }
    color: ${props => 
        props.success ? '#26AF2B'
        : props.warning ? '#FF9800'
        : props.danger ? '#EF5350'
        : '#818080'
    };
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &:after {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        bottom: -30px;
        height: 30px;
        background-color: ${props =>
            props.success ? '#E8F5F9'
            : props.warning ? '#FFF3E0'
            : props.danger ? '#FFEBEE'
            : '#f5f5f5'
        }
    }
`;
const InputWrapper = styled.div`
    position: relative;
    overflow: hidden;
    border: 1px solid ${props =>
        props.success ? '#26AF2B'
        : props.warning ? '#FF9800'
        : props.danger ? '#EF5350'
        : '#C1C1C1'
    };
    border-radius: ${props => props.rounded ? '50px' : '6px'};
    margin-bottom: .5rem;
    z-index: 1;
`;
const Icon = styled.i`
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 1.5rem;
    color: ${props => 
        props.success ? '#26AF2B'
        : props.warning ? '#FF9800'
        : props.danger ? '#EF5350'
        : '#818080'
    };
`;
const Input = styled.input`
    width: 100%;
    height: 50px;
    border: none;
    box-shadow: none;
    outline: none;
    color: ${props => 
        props.success ? '#26AF2B'
        : props.warning ? '#FF9800'
        : props.danger ? '#EF5350'
        : '#222'
    };
    padding: ${props => props.icon ? '0 3rem 0 1rem' : '0 1rem 0 1rem'};
    text-align: right;
    &::-webkit-input-placeholder {
        color: #818080
    }
`;

export default (props) => {
    return (
        <InputMessageWrapper>
            {
                props.message && 
                <InputMessage
                    success={props.success}
                    warning={props.warning}
                    danger={props.danger}
                >
                    {props.message}
                </InputMessage>
            }
            <InputWrapper
                rounded={props.rounded}
                success={props.success}
                warning={props.warning}
                danger={props.danger}
            >
                {props.icon && <Icon
                    success={props.success}
                    warning={props.warning}
                    danger={props.danger}
                    className={props.icon}
                />}
                <Input {...props} />
            </InputWrapper>
        </InputMessageWrapper>
    )
}