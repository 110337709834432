import React, { Component } from 'react';
import { StepHolder } from '../../../components';
import Finish from './finish';

class Step extends Component {
    render() {
        return (
            this.props.currentStep > this.props.items.length ? (
                <Finish />
            ) : (
                (this.props.items && this.props.items.length) ? this.props.items.map((item, i) => (
                    <StepHolder
                        key={item.id}
                        active={this.props.currentStep === (i + 1)}
                        currentStep={this.props.currentStep}
                        allSteps={this.props.allSteps}
                        id={item.id}
                        stepTitle={item.question.replace('[name]', this.props.name)}
                        answers={item.answers}
                        onSkip={this.props.onSkip}
                        onAdd={this.props.onAdd}
                    />
                )) : 'درحال دریافت اطلاعات...'
            )
        )
    }
}

export default Step;