import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
    font-weight: ${props => props.weight ? props.weight : '300'};
    font-size: ${props => props.size ? `${props.size}px` : '1rem'};
    color: ${
        props => props.danger ? '#EF5350'
        : props.success ? '#26AF2B'
        : props.warning ? '#FF9800'
        : props.info ? '#8290D8'
        : '#818080'
    };
    text-align: ${props => props.align ? props.align : 'right'};
`;

export default (props) => (
    <Text {...props}>{props.children}</Text>
)