import React from 'react';
import styled from 'styled-components';
import { DefaultLayout } from '../../layout';
import { Text } from '../../components';

const Wrapper = styled.div`
    margin-top: 4rem;
    text-align: center;
`;
const Title = styled.h1`
    font-size: 2.5rem;
    color: #f61a53;
    margin-bottom: 2rem;
`;
const LinkedButton = styled.a`
    display: inline-block;
    padding: .5rem 2rem;
    text-align: center;
    color: #fff;
    box-shadow: 0px 4px 10px #f61a53;
    border: 1px solid #f61a53;
    border-radius: 100px;
    background-color: #f61a53;
    margin-bottom: 1.5rem;
    outline: none !important;
    &:hover {
        color: #fff;
    }
`;

export default () => {
    return (
        <DefaultLayout title="درخواست تبلیغات">
            <Wrapper>
                <Title>درخواست تبلیغات</Title>
                <Text align="center">
                    درصورتی که قصد دارید در پلتفرم ما تبلیغ کنید، میتوانید از طریق لینک زیر وارد صفحه اینستاگرام ما شده و با ارسال پیام به دایرکت، با ما در ارتباط باشید (:
                </Text>
                <LinkedButton href="https://www.instagram.com/adelina.ir/" target="_blank" rel="nofollow">رفتن به اینستاگرام</LinkedButton>
            </Wrapper>
        </DefaultLayout>
    )
}