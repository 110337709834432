import React, { Component } from 'react';
import { Provider } from 'trim-redux';
import Pages from './pages';
import store from './utils/store';

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Pages />
      </Provider>
    )
  }
}
