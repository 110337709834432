import React from 'react';
import styled, {keyframes} from 'styled-components';

const keyFrame1 = keyframes`
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
`;
const keyFrame2 = keyframes`
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
`;
const keyFrame3 = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
`;
const Div = styled.div`
    display: inline-block;
    position: relative;
    width: 64px;
    height: ${
        props => props.small ? '10px'
        : props.large ? '20px'
        : '15px'
    };
`;
const innerDiv =`
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
`;
const InnerDiv1 = styled.div`
    ${innerDiv}
    top: ${
        props => props.small ? '2px'
        : props.large ? '10px'
        : '7px'
    };
    background: ${props => props.bgColor ? props.bgColor : '#fff'};
    left: 6px;
    animation: ${keyFrame1} 0.6s infinite;
`;
const InnerDiv2 = styled.div`
    ${innerDiv}
    top: ${
        props => props.small ? '2px'
        : props.large ? '10px'
        : '7px'
    };
    background: ${props => props.bgColor ? props.bgColor : '#fff'};
    left: 6px;
    animation: ${keyFrame2} 0.6s infinite;
`;
const InnerDiv3 = styled.div`
    ${innerDiv}
    top: ${
        props => props.small ? '2px'
        : props.large ? '10px'
        : '7px'
    };
    background: ${props => props.bgColor ? props.bgColor : '#fff'};
    left: 26px;
    animation: ${keyFrame2} 0.6s infinite;
`;
const InnerDiv4 = styled.div`
    ${innerDiv}
    top: ${
        props => props.small ? '2px'
        : props.large ? '10px'
        : '7px'
    };
    background: ${props => props.bgColor ? props.bgColor : '#fff'};
    left: 45px;
    animation: ${keyFrame3} 0.6s infinite;
`;

export default (props) => {
    return (
        <Div {...props}>
            <InnerDiv1  {...props} />
            <InnerDiv2  {...props} />
            <InnerDiv3  {...props} />
            <InnerDiv4  {...props} />
        </Div>
    )
}