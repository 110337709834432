import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CircularProgressbar } from 'react-circular-progressbar';
import { DefaultLayout } from '../../layout';
import { LinearLoading, Text } from '../../components';

const Wrapper = styled.div`
    margin-top: 4rem;
    text-align: center;
`;
const Title = styled.h1`
    font-size: 2.5rem;
    color: #f61a53;
    margin-bottom: 2rem;
`;
const NoResult = styled(Text)`
    color: #f61a53;
    text-align: center;
`;
const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    &:not(:last-child){
        border-bottom: 1px solid #dddddd52;
    }
`;
const LinkWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    width: 100%;
    padding: .5rem;
    border: 1px solid #ccc;
    border-radius: 100px;
`;
const Link = styled.span`
    max-width: 55%;
    font-family: sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #333;
    direction: ltr;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
const CopyButton = styled.button`
    background: none;
    border: none;
    outline: none !important;
    color: #333;
    padding: .2rem;
`;


class Results extends Component{
    state = {
        results: [],
        loading: true
    }
    componentDidMount = () => {
        this.fetchData();
    }
    fetchData = async () => {
        const id = this.props.match.params.id;

        try {

            const response = await axios({
                method: 'get',
                url: `https://quiz.programmerboy.ir/api/v2/results/${id}`
            });

            if( response.status === 200 ){
                const results = response.data;
                this.setState(({ loading: false, results }));
                console.log(results);
            }

        } catch(e) {
            // Search for console#log
            (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && console.log(e);
        }
    }
    calcPercent = (percentFor, percentOf) => {
        return Math.floor(percentFor/percentOf*100);
    }
    setProgreccColor = (range) => {
        return range < 20 ? '#F44336'
        : range < 40 ? '#FF5722'
        : range < 60 ? '#FF9800'
        : range < 70 ? '#FFEB3B'
        : range < 80 ? '#8BC34A'
        : range < 90 ? '#00BCD4'
        : range <= 100 ? '#2196F3' : '#78909C'
    }
    render(){
        return (
            <DefaultLayout title="مشاهده نتایج">
                <Wrapper>
                    {this.state.loading ? (
                        <div style={{ textAlign: 'center' }}>
                            <LinearLoading bgColor="#f61a53" />
                        </div>
                    ) : (
                        <>
                            <Text align="center">لینک اختصاصی شما</Text>
                            <LinkWrapper>
                                <CopyToClipboard
                                    text={`https://adelina.ir/${this.props.match.params.id}`}
                                    onCopy={() => alert('لینک کپی شد!')}
                                >
                                    <CopyButton>کپی کن</CopyButton>
                                </CopyToClipboard>
                                <Link>https://adelina.ir/{this.props.match.params.id}</Link>
                            </LinkWrapper>

                            <br />

                            <Title>نتایج</Title>
                            <Text align="center">
                                در این قسمت میتوانید مشاهده کنید چه کسانی به سوالات شما پاسخ داده اند و چه امتیازی دارند.
                            </Text>
                            {
                                this.state.results.length ? (
                                    this.state.results.map((result, i) => {
                                        return (
                                            <Row key={i}>
                                                <span>{result.fullname}</span>
                                                <CircularProgressbar
                                                    styles={{
                                                        root: { width: '50px' },
                                                        path: { stroke: this.setProgreccColor( this.calcPercent(result.score, result.count) ) },
                                                        text: { fill: '#000' }
                                                    }}
                                                    value={this.calcPercent(result.score, result.count)}
                                                    text={`${this.calcPercent(result.score, result.count)}%`}
                                                />
                                            </Row>
                                        )
                                    })
                                ) : (
                                    <NoResult>
                                        هنوز نتیجه ای ثبت نشده
                                    </NoResult>
                                )
                            }
                        </>
                    )}
                </Wrapper>
            </DefaultLayout>
        )
    }
}

export default Results;