import React from 'react';
import styled from 'styled-components';
import Text from './text';
import Items from './items';
import Button from './button';

const SteoHolder = styled.div`
    margin-top: 7rem;
    display: ${props => props.active ? 'block' : 'none'};
`;
const StepCounter = styled(Text)`
    font-family: IRANSansFaNum;
`;
const StepTitle = styled.h3`
    font-size: 18px;
    font-weight: 400;
    text-align: right;
    margin-bottom: 2rem;
    &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #f61a53;
        margin-left: .5rem;
    }
`;
const NextButtonWrapper = styled.div`
    display: block;
    text-align: center;
    margin-top: 1.5rem;
`;

export default (props) => {
    return (
        <SteoHolder active={props.active}>
            <StepCounter align="right">
                <span>{props.currentStep}</span> از <span>{props.allSteps}</span>
            </StepCounter>
            <StepTitle>{props.stepTitle}</StepTitle>

            <Items
                id={props.id}
                items={props.answers}
                onAdd={props.onAdd}
                correctAnswer={props.correctAnswer && props.correctAnswer}
            />

            {!props.isQuiz ? (
                <NextButtonWrapper>
                    <Button
                        onClick={props.onSkip && props.onSkip}
                        rounded
                        danger
                    >
                        رد کردن سوال
                    </Button>
                </NextButtonWrapper>
            ) : null}
        </SteoHolder>
    )
}