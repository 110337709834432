import React from 'react';
import styled from 'styled-components';
import svg from '../static/wrapper.svg';

const Header = styled.header`
    display: flex;
    justify-content: center;
    position: fixed;
    top: .7rem;
    right: .7rem;
    left: .7rem;
    height: 40px;
    z-index: 1;
`;
const HeaderSVG = styled.img`
    transform: rotate(180deg) translateY(15px);
    width: 400px;
`;
const Title = styled.span`
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
`;

export default (props) => {
    return (
        <Header>
            <HeaderSVG src={svg} alt="header" />
            {props.title && <Title>{props.title}</Title>}
        </Header>
    )
}