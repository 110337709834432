import React from 'react';
import styled from 'styled-components';

const Main = styled.main`
    width: 100%;
    height: 100%;
    padding: ${
        props => props.side === 'top' ? '.7rem 0 0 0'
        : props.side === 'bottom' ? '0 0 .7rem 0'
        : props.side === 'right' ? '0 .7rem 0 0'
        : props.side === 'left' ? '0 0 0 .7rem'
        : props.side === 'yy' ? '.7rem 0'
        : props.side === 'xx' ? '0 .7rem'
        : '.7rem'
    };
    background: #f61a53;
    overflow: hidden;
`;
const Holder = styled.div`
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 1rem 1rem;
    padding-bottom: 60px;
    border-radius: 20px;
    overflow: auto;
`;

export default (props) => {
    const {children, ...rest} = props;
    return (
        <Main {...rest}>
            <Holder>
                {children}
            </Holder>
        </Main>
    )
}