import React from 'react';
import styled from 'styled-components';
import { connect, setStore } from 'trim-redux';
import { Input, Text, Button } from '../../../components';

const Title = styled.h1`
    font-size: 22px;
    font-weight: 500;
    padding: 0 1.5rem;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
`;
const Form = styled.form`
    text-align: center;
`;

class Start extends React.Component{
    state = {
        message: '',
        messageType: '',
    }
    handleChange = (e) => {
        setStore({ name: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const name = this.props.name;
        
        if( name !== '' ){

            this.props.onSubmit();
            this.setState(({
                message: '',
                messageType: '',
                loading: true
            }));

        } else {

            this.setState(({
                message: 'لطفا نام رو وارد کنید',
                messageType: 'warning'
            }));

        }
    }
    render(){
        return (
            <>
                <Title>
                    چقدر {this.props.fullname} رو میشناسی؟<br/>
                    ثابت کن!
                </Title>
                <Text align="center">توی چندمرحله به یه سری سوال ها پاسخ میدی که در آخر مشخص میشه چقدر شناخت درست از {this.props.fullname} داری. آماده ای؟</Text>
                <Form onSubmit={this.handleSubmit} autoComplete="off">
                    <Input
                        type="text"
                        name="fullname"
                        placeholder="نام و نام خانوادگی"
                        value={this.props.name}
                        onChange={this.handleChange}
                        warning={this.state.messageType === 'warning'}
                        message={this.state.message}
                        rounded
                    />
                    <Button
                        rounded
                        danger
                        onClick={this.handleSubmit}
                    >
                        بزن بریم !
                    </Button>
                </Form>
            </>
        )
    }
}

const mstp = state => ({
    name: state.name
})
export default connect(mstp)(Start)